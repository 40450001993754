<template>
    <div class="container">
        <h1 class="text-align-center" id="companyCultural">企业文化</h1>
        <h4 class="text-align-center">Corporate culture</h4>
        <div class="content">
            <div class="intro">
                <div class="img">
                    <img width="100%" height="100%" src="../../../assets/image/culturalImg.png" alt="">
                </div>
                <div class="content font-18 font-color-white">
                    <div class="catchword">
                        <div class="font-26 font-color-white">
                            <span>
                                质量第一&nbsp;&nbsp;顾客至上
                            </span>
                            <p class="font-26 font-color-white">科技创新  精益求精</p>
                        </div>
                        
                    </div>
                    <div class="text">
                        <h3>成都光量信息技术有限公司</h3>
                        <p>
                            公司秉承“质量第一、顾客至上、科技创新、精益求精”的经营理念，以市场为导向，以产品为中心，以顾客关注为焦点，用扁平化的管理架构来给员工创造一个自由发挥的平台。
                        </p>
                        <div class="tabCards flex alignCenter">
                            <div class="card" v-for="item in cardList"  :key="item.sortNumber" >
                                <el-row>
                                    <el-col :span="24">
                                        <div class="iconBg">
                                            <img :src="item.icon" alt="">
                                        </div>
                                    </el-col>
                                </el-row>
                                <div>
                                    <div class="text-align-center font-16" >{{item.title}}</div>
                                    <div class="text-align-center font-color-white font-12">{{ item.titleInEnglish }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent,reactive,ref } from 'vue';
import cooperation from "@/assets/image/cooperation.png";
import innovate from "@/assets/image/innovate.png";
import quality from "@/assets/image/quality.png";
import technology from "@/assets/image/technology.png";
  export default defineComponent({
    name:'companyCultural',
    components: {
    },
    setup (props, ctx) {
        interface cardItem{
            sortNumber:string,
            isActive:boolean,
            title:string,
            titleInEnglish:string,
            icon:string
        }
        let cardList = reactive([
            {
                sortNumber:'01',
                title:'技 术',
                titleInEnglish:'technique',
                icon:technology
            },{
                sortNumber:'02',
                title:'质 量',
                titleInEnglish:'quality',
                icon:quality
            },{
                sortNumber:'03',
                title:'创 新',
                titleInEnglish:'innovation',
                icon:innovate
            },{
                sortNumber:'04',
                title:'合 作',
                titleInEnglish:'co-operation',
                icon:cooperation
            },
        ])
        return{
            cardList,
        }
    },
  });
</script>
<style scoped lang="less">
.container{
    margin-bottom: 40px;
    padding: 0 10%;
}
.content{
    min-width: 500px;
    padding:0 50px;
    .tabCards{
        margin-top: 30px;
        width: 100%;
        .card{
            cursor: pointer;
            width: calc(25% - 16px);
            white-space: nowrap;
            color:white;
            padding: 12px;
            margin-left: 16px;
            background-color: #AC2C2C;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sortNumber{
                color: #CBCBCB;
            }
        }
        .isActive{
            background-color: #AC2C2C;
            color: #AC2C2C;
        }
        & .card:nth-child(1){
            margin: 0;
        }
    }
    
}
.intro{
    position: relative;
    display: flex;
    .img{
        display: inline-block;
        max-width: 330px;
        width: 30%;
        max-height: 500px;
    }
    .catchword{
        display: inline-block;
        margin-left: -160px;
        float: left;
        margin-top: 250px;
        margin-right: 30px;
        & span{
            position: relative;
            text-align: left;
        }
        & span:after{
            position: absolute;
            right: 0;
            content:'';
            display: block;
            width: 100%;
            border-bottom: 6px solid rgb(0, 76, 255);
        }
        & p{
            position: relative;
            text-align: right;
            padding-left: 60px;
        }
        & p:after{
            position: absolute;
            right: 0;
            content:'';
            display: block;
            width: 80%;
            border-bottom: 6px solid rgb(0, 76, 255);
        }
    }
    .content{
        width: calc(100% - 550px);
        margin-left: 120px;
        display: inline-block;
        background-image: url(../../../assets/image/culturBackImg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // opacity: 60%;
        height: 500px;
        .text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            // background-image: url(../../../assets/image/culturBackImg.png);
            // opacity: 60%;
        }
    }
}
</style>