<template>
    <div class="aboutUs">
        <h1 class="text-align-center">关于光量</h1>
        <h4 class="text-align-center">About us</h4>
        <div class="content">
            <div class="intro flex alignCenter">
                <div class="img">
                    <img width="100%" height="100%" :src="imgUrl" alt="">
                </div>
                <div class="content font-18">
                    <p v-for="(item,index) in introductionList" :key="index">{{ item }}</p>
                </div>
            </div>
            <div class="tabCards flex alignCenter">
                <div class="card" v-for="item in cardList" :class="item.isActive?'isActive':''" :key="item.sortNumber" @click="changeCard(item)">
                    <el-row>
                        <el-col :span="12">
                            <div class="iconBg">
                                <el-icon size="24" >
                                    <component :is="item.icon"/>
                                </el-icon>
                            </div>
                        </el-col>
                        <el-col :span="12" class="text-align-end">
                           <span class="font-28 sortNumber" >{{item.sortNumber}}</span>
                        </el-col>
                    </el-row>
                    <div>
                        <div class="text-align-end font-color-white font-26">{{item.title}}</div>
                        <div class="text-align-end font-color-gray-light font-12">{{ item.titleInEnglish }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
  import { defineComponent,reactive,ref } from 'vue';
  import settingpng from "@/assets/image/settingpng.png";
  import business from "@/assets/image/business.png";
  import memo from "@/assets/image/memo.png";
  import introImg from "@/assets/image/introImg.jpg";
  export default defineComponent({
    name:'aboutUs',

    components: {
    },
    setup (props, ctx) {
        let imgUrl = ref(introImg)
        let sortNumber = ref("01")
        let introductionList = ref([
            '成都光量信息技术有限公司专注于信息技术领域高科技产品及系统软、硬件研发，是集研发、生产、销售、服务于一体的高新技术企业。',
            '公司拥有自主研发设计、技术应用以及系统集成的工程服务能力，基于核心技术，为各领域用户提供先进完整的系统解决方案及具体实施方案。'
        ])
        interface cardItem{
            sortNumber:string,
            isActive:boolean,
            title:string,
            titleInEnglish:string,
            icon:string,
            img:string
            introduction:Array<string>
        }

        let cardList = reactive([
            {
                sortNumber:'01',
                isActive:true,
                title:'公司简介',
                titleInEnglish:'Company Introduction',
                icon:'School',
                img:introImg,
                introduction:[
                    '成都光量信息技术有限公司专注于信息技术领域高科技产品及系统软、硬件研发，是集研发、生产、销售、服务于一体的高新技术企业。',
                    '公司拥有自主研发设计、技术应用以及系统集成的工程服务能力，基于核心技术，为各领域用户提供先进完整的系统解决方案及具体实施方案。'
                ]
            },{
                sortNumber:'02',
                isActive:false,
                title:'主营业务',
                titleInEnglish:'Main business',
                icon:'SuitcaseLine',
                img:business,
                introduction:[
                    "嵌入式软硬件系统解决方案",
                    "国产化硬件平台解决方案",
                    "电子模块、组件、设备、仪器定制开发",
                    "计算机软件、手机软件设计开发",
                ]
            },{
                sortNumber:'03',
                isActive:false,
                title:'知识产权',
                titleInEnglish:'intellectual property right',
                icon:'Memo',
                img:memo,
                introduction:[
                    "公司拥有国家授权专利2项，其中发明专利1项",
                    "集成电路布图设计专有权2项",
                    "计算机软件著作权8项",
                ]
            },{
                sortNumber:'04',
                isActive:false,
                title:'科研生产能力',
                titleInEnglish:'Research and production capacity',
                icon:'Setting',
                img:settingpng,
                introduction:[
                    "公司核心团队具有十余年从业经验，是一支具有创新意识、拥有共同目标、有着不同专业背景的队伍，涵盖医疗、军工等行业，团队在医疗军工领域发表6篇论文，拥有2项专利。",
                    "公司划分独立研发区域，并配备专业专用高精生产及测试设备，保障公司顺利进行研发，同时聘请外聘专家，为公司产品开发做重大指导，多方面保障公司科研生产能力"
                ]
            },
        ])
        let changeCard = (item:cardItem)=>{
            cardList.map(data=>{
                data.isActive = false
            })
            item.isActive = true
            imgUrl.value = item.img
            sortNumber.value = item.sortNumber
            introductionList.value = item.introduction
        }
        return{
            imgUrl,
            sortNumber,
            introductionList,
            cardList,
            changeCard
        }
    },
  });
</script>
<style scoped lang="less">
.aboutUs{
    margin-bottom: 40px;
    padding: 0 10%;
}
.content{
    padding:0 50px;
    .tabCards{
        margin-top: 30px;
        .card{
            cursor: pointer;
            width: calc(25% - 16px);
            color:#696969;
            padding: 12px;
            margin-left: 16px;
            background-color: #696969;
            .iconBg{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                text-align: center;
                line-height: 50px;
                background-color: #FFF;
            }
            .sortNumber{
                color: #CBCBCB;
            }
        }
        .isActive{
            background-color: #AC2C2C;
            color: #AC2C2C;
        }
        & .card:nth-child(1){
            margin: 0;
        }
    }

}
.intro{
    .img{
        flex:1;
    }
    .content{
        padding: 0 0 0 100px;
        flex: 1;
        white-space:wrap;
        p:before{
            content:'';
            margin-right: 20px;
            display: inline-block;
            background-color: #fff;
            width: 14px;
            height: 14px;
            border-radius:50% ;
        }
    }
}
</style>
