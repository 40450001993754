import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-292b5808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about" }
const _hoisted_2 = { class: "carousel" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_aboutUs = _resolveComponent("aboutUs")!
  const _component_companyCultural = _resolveComponent("companyCultural")!
  const _component_companyTarget = _resolveComponent("companyTarget")!
  const _component_bottomNav = _resolveComponent("bottomNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_carousel, {
        "motion-blur": "",
        height: "600px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannerList, (item) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: item }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  width: "100%",
                  height: "100%",
                  src: item,
                  alt: ""
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_aboutUs),
      _createVNode(_component_companyCultural),
      _createVNode(_component_companyTarget)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_bottomNav)
    ])
  ]))
}