<template>
    <div class="bottom">
      <el-row class="fit">
        <el-col :span="12">
          <div class="flex alignCenter">
            <img height="50" src="../assets/image/logo_btm.png" alt="">
            <div>
              <span class="companyName font-20 font-weight">成都光量信息技术有限公司</span>
            </div>
          </div>
          <div class="font-color-gray-light leftBox text-align-start">
            <p>电话：028-60620782</p>
            <p>邮箱：sushan@light-quantum.com</p>
            <p>地址：四川•成都</p>
          </div>
        </el-col>
        <el-col class="rightBox text-align-end font-color-gray-light" :span="12">
          <div class="font-18 font-weight font-color-gray-light">咨询服务热线</div>
          <div class="font-36 font-color-white font-weight">028-60620782</div>
          <el-icon size="50"><Location /></el-icon>
          <p style="text-align: end;">四川•成都</p>
        </el-col>
        <el-col class="rightBox text-align-end font-color-gray-light" :span="24">
          <div class="text-align-center font-12 font-color-gray-light">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">
              Copyright &copy; 2020 蜀ICP备2024079357号-1
            </a>
          </div>
        </el-col>
      </el-row>
    </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    components: {
    },
    setup (props, ctx) {
      console.log('setup',props,ctx)
    },
  });
</script>
<style scoped lang="less">
a{
  text-decoration: none;
  color: #B9B9B9;
}
.bottom{
  height: 300px;
  background-color: #515151;
  padding: 50px 50px 0 60px;
  // position: absolute;
  bottom: 0;
  width: calc(100% - 100px);
  .companyName{
    margin-left: 20px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 0.42819148302078247px;
    font-family: PingFangSC-Semibold;
    text-align: left;
    white-space: nowrap;
    line-height: 32px;
  }
  .leftBox{
    margin-left: 120px;
    p{
      margin: 40px 0;
    }
  }
  .rightBox{
    & div:nth-child(2){
      margin-top: 12px;
    }
    & i{
      margin-top: 16px;
    }
  }
}
</style>
