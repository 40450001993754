<template>
    <div class="container">
        <h1 class="text-align-center">聆听世界与我们的声音</h1>
        <h4 class="text-align-center">Listen to the world and our voices</h4>
        <div class="content fit">
            <el-row class="fit">
                <el-col class="" :span="8">
                    <!-- <div class="fit" style="width: 110px;display: inline-block;">
                        <img class="markeUp" :src="imgList[0]" alt="">
                    </div> -->
                    <div class="font-26 font-color-white" style="margin-left: 23%;margin-top: 10%;">
                        <div >
                            <div>聆听世界</div>
                            <div>与我们的声音</div>
                        </div>
                        <img  class="arrow" :src="imgList[2]" alt="">
                    </div>
                </el-col>
                <el-col class="flex fit" style="flex-direction: column;align-items: flex-end;" :span="16">
                    <video style="width: 90%;height: 100%;margin-right:50px ;" controls poster="../../../assets/image/videoPoster.png">
                        <source src="../../../assets/video/listen.mp4">
                    </video>
<!--                    <div class="fit" style="width: 110px;display: inline-block;position: relative;">-->
<!--                        <img style="position: absolute;bottom: 0;right: 0;" class="markeDown" :src="imgList[1]" alt="">-->
<!--                    </div>-->
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script lang="ts">
  import { defineComponent,reactive,ref } from 'vue';
  import markeUp from "@/assets/image/markeUp.png";
  import markeDown from "@/assets/image/markeDown.png";
  import arrow from "@/assets/image/arrow.png";
  export default defineComponent({
    name:'companyTarget',
    components: {
    },
    setup () {
        let imgList:any = reactive([markeUp,markeDown,arrow])
        return{
            imgList,
        }
    }
  });
</script>
<style scoped lang="less">
.container{
    margin-bottom: 40px;
    padding: 0 10%;
}
.content{
    height: 600px;
    img{
        display: block;
    }
    .markeUp{

    }
}
</style>
