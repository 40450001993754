<template>
  <div class="about">
    <div class="carousel">
      <el-carousel motion-blur height="600px" >
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img width="100%" height="100%" :src="item" alt="" >
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <aboutUs/>
      <companyCultural/>
      <companyTarget/>
    </div>
    <div class="bottom">
      <bottomNav />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent,ref,reactive, watch, nextTick } from 'vue';
import banner1 from "@/assets/image/banner1.png";
import banner2 from "@/assets/image/banner2.png";
import banner3 from "@/assets/image/banner3.png";
import banner4 from "@/assets/image/banner4.png";
import bottomNav from "@/components/bottomNav.vue";
import aboutUs from "./components/aboutUs.vue"
import companyCultural from "./components/companyCultural.vue"
import companyTarget from "./components/companyTarget.vue"
export default defineComponent ({
  name:'aboutView',
  props:{
    isScoll:{
        type:Boolean,
        defualt:false
    }
  },
  components:{
    bottomNav,
    aboutUs,
    companyCultural,
    companyTarget,
  },
  setup (props, ctx) {
      let activeName:any = ref('first');
      let bannerList:any = reactive([banner1,banner2,banner3,banner4]);
      console.log(props.isScoll)
      watch(()=>props.isScoll,(value:boolean)=>{
        console.log(document.getElementById('companyCultural'))
        value&&nextTick(()=>{
          document.getElementById('companyCultural')?.scrollIntoView({
            behavior:'smooth'
          })
        })
      },{immediate:true,deep:true})
      return{
          activeName,
          bannerList,//banner列表
      }
  },
})
</script>
<style scoped lang="less">
.demonstration {
  color: var(--el-text-color-secondary);
}
.content{
  min-height: calc(100% - 300px);
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>