<template>
    <div class="about">
        <div class="carousel">
            <img width="100%" height="100%" src="@/assets/image/bg_us.png" alt="" >
            <img width="100%" height="100%" src="@/assets/image/logo_us.png" alt="" >
        </div>
        <div class="content">
            <h1 class="text-align-center">联系我们</h1>
            <h4 class="text-align-center">Contact us</h4>
            <div class="flex justifyCenter" style="margin-bottom:100px">
                <div class="card" v-for="item in cardList"  :key="item.sortNumber" >
                    <el-row>
                        <el-col :span="24">
                            <div class="iconBg">
                                <img :src="item.icon" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    <div>
                        <p class="text-align-center font-color-white font-16" >{{item.title}}</p>
                        <p class="text-align-center font-color-white font-16">{{ item.subTitle }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <bottomNav />
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent,ref,reactive, watch, nextTick } from 'vue';
    import bottomNav from "@/components/bottomNav.vue";
    import phone from "@/assets/image/phone.png";
    import email from "@/assets/image/email.png";
    import location from "@/assets/image/location.png";
    export default defineComponent ({
        name:'investorRelations',
        components:{
            bottomNav,
        },
        setup (props, ctx) {
            interface cardItem{
                sortNumber:string,
                isActive:boolean,
                title:string,
                titleInEnglish:string,
                icon:string
            }
            let cardList = reactive([
                {
                    sortNumber:'01',
                    title:'Tel.',
                    subTitle:'028-60620782',
                    icon:phone
                },{
                    sortNumber:'02',
                    title:'Email',
                    subTitle:'sushan@light-quantum.com',
                    icon:email
                },{
                    sortNumber:'03',
                    title:'Address',
                    subTitle:'四川•成都',
                    icon:location
                },
            ])
            return{
                cardList,
            }
        },
    })
</script>
<style scoped lang="less">
    .demonstration {
        color: var(--el-text-color-secondary);
    }
    .content{
        min-height: calc(100% - 300px);
        margin: 100px 0 220px 0;
    }
    .carousel{
        height:400px;
        position: relative;
        img:nth-child(2){
            width: 400px;
            height: 200px;
            position: absolute;
            z-index: 99;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .card{
            width: calc(25% - 16px);
            color:#696969;
            padding: 12px;
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .sortNumber{
                color: #CBCBCB;
            }
        }
</style>