<template>
  <div class="container">
      <img src="@/assets/image/logo.png" alt="logo">
      <el-tabs v-model="activeName" class="demo-tabs" :before-leave="changeHeader"  @tab-click="handleClick">
          <el-tab-pane label="关于光量" name="first">
            <aboutView v-if="activeName==='first'" :isScoll="isScoll"/>
          </el-tab-pane>
          <el-tab-pane label="企业文化" :lazy="true" name="second">
            <aboutView v-if="activeName==='second'" :isScoll="!isScoll"/>
          </el-tab-pane>
          <el-tab-pane label="投资者关系" name="third">
            <investorRelations/>
          </el-tab-pane>
          <el-tab-pane label="联系我们" name="fourth">
            <contactUs />
          </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent,ref } from 'vue';
import type { TabsPaneContext } from 'element-plus';
import aboutView from './AboutView/AboutView.vue';
import investorRelations from './investorRelations/investorRelations.vue';
import contactUs from './contactUs/contactUs.vue';
export default defineComponent({
  name: 'navBar',
  components: {
    aboutView,
    investorRelations,
    contactUs,
  },
  setup (props, ctx) {
      let activeName:any = ref('first');
      let isScoll:any = ref(false);
      const handleClick = (tab: TabsPaneContext, event: Event) => {
          console.log(tab, event)
      }
      const changeHeader = (activeName: string, oldActiveName: string) => {
          isScoll = activeName==='second'
          return true
      }
      
      return{
          activeName,
          isScoll,
          handleClick,
          changeHeader
      }
  },
});
</script>
<style scoped lang="less">
.container{
  height: 100%;
  color:#FFFFFF;
  background-color: #3E3E3E;
  img{
      height: 60px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;
  }
}
/deep/.el-tabs__header{
  margin: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
/deep/.el-tabs__item:hover {
  color: white;
  cursor: pointer;
}
/deep/.el-tabs__active-bar{
  background-color:#D90505;
}
/deep/.el-tabs__item.is-active{
  font-size: 16px;
  color: white;
}
/deep/.el-tabs__nav{
  float: right;
  margin-right: 20px;
}
/deep/.el-tabs__nav-wrap:after{
  height:0;
}
/deep/.el-tabs__item{
  color: white;
}
/deep/.el-tabs{
  margin-top: 60px;
}
/deep/.el-tabs__nav-scroll{
  background-color: #3E3E3E;
  padding: 10px;
}
/deep/.el-tabs__content{
  overflow-x: hidden;
  overflow-y: auto;
  height:calc(100vh - 60px)
}
</style>