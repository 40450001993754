<template>
    <div class="about">
        <div class="carousel">
            <img width="100%" height="100%" src="@/assets/image/investor.png" alt="" >
        </div>
        <div class="content">
            <h1 class="text-align-center">投资者互动</h1>
            <h4 class="text-align-center">Investor interaction</h4>
            <div style="height:200px"></div>
        </div>
        <div class="bottom">
            <bottomNav />
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent,ref,reactive, watch, nextTick } from 'vue';
    import bottomNav from "@/components/bottomNav.vue";
    export default defineComponent ({
        name:'investorRelations',
        components:{
            bottomNav,
        },
        setup (props, ctx) {
            console.log(props, ctx)
        },
    })
</script>
<style scoped lang="less">
    .demonstration {
        color: var(--el-text-color-secondary);
    }
    .content{
        min-height: calc(100% - 300px);
    }
    .carousel{
        height:600px
    }
</style>